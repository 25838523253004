import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import React from "react";

import { SliceVariants } from "@components/SliceVariants";

import { components } from "./variants";

/**
 * Props for `BannerItem`.
 */
export type BannerItemProps = SliceComponentProps<Content.BannerItemSlice>;

/**
 * Component for "BannerItem" Slices.
 */
const BannerItem = ({ slice, ...config }: BannerItemProps): JSX.Element => (
  <SliceVariants {...config} components={components} slice={slice} />
);

export default BannerItem;
