import { paths } from "@paths";
import { asLink } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";

import { Button } from "@components/Button";
import { Container } from "@components/Container";
import { useIsMdUp } from "@hooks/responsive";
import { useIntersectionObserver } from "@hooks/useIntersectionObserver";
import {
  gtmSelectPromotion,
  gtmViewPromotion,
} from "@providers/GoogleTagManagerProvider";

import { BannerItemProps } from "../..";

import * as S from "./styles";

const BannerItem = ({ slice }: BannerItemProps): JSX.Element => {
  const bannerRef = useRef(null);
  const { push } = useRouter();
  const isMdUp = useIsMdUp();

  const {
    primary: {
      title = "Banner without title",
      background_colour = "default",
      sub_title,
      description,
      button_link,
      button_text,
      image,
      image_size = "500",
      layout = "left",
    },
  } = slice;

  const url = isMdUp ? image?.url : image?.mobile?.url ?? image?.url;

  const entry = useIntersectionObserver(bannerRef, {
    freezeOnceVisible: true,
    threshold: 0.8,
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      gtmViewPromotion(title!);
    }
  }, [isVisible]);

  const handleClick = () => {
    gtmSelectPromotion(title!);
    push(asLink(button_link) || paths.home);
  };

  return (
    <S.BannerWrapper>
      <Container
        background={background_colour}
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
      >
        <S.BannerItemWrapper ref={bannerRef} variant={layout}>
          <S.DescriptionWrapper>
            <S.Description>
              {title && <S.Title>{title}</S.Title>}
              {sub_title && <S.SubTitle>{sub_title}</S.SubTitle>}
              {description && (
                <S.InfoWrapper>
                  <PrismicRichText field={description} />
                </S.InfoWrapper>
              )}
            </S.Description>
            <Button onClick={handleClick} variant="secondary">
              {button_text}
            </Button>
          </S.DescriptionWrapper>
          <S.ImageWrapper imageSize={image_size}>
            {url && (
              <Image
                alt={image?.alt! || sub_title || ""}
                layout="fill"
                src={url}
                title={sub_title || "Slide title"}
              />
            )}
          </S.ImageWrapper>
        </S.BannerItemWrapper>
      </Container>
    </S.BannerWrapper>
  );
};

export default BannerItem;
